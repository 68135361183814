<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="pull-left">
              <h4 class="card-title" style="text-align: center">Qo'yilgan imzolar ro'yxati</h4>
            </div>
            <div class="pull-right">
              <div class="input-group search-box">
                <input class="form-control" type="text" :placeholder="'Izlash...'" v-model="filter.search" @keypress.enter="initList">
                <div class="input-group-append">
                  <button class="btn btn-info" @click="initList">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th class="app-w-10p">ID</th>
                <th class="app-w-10p">Table Uuid</th>
                <th class="app-w-10p">Table Name</th>
                <th class="app-w-10p">Topic</th>
                <th class="app-w-5p">Offset</th>
                <th class="app-w-5p">Partition</th>
                <th class="app-w-10p">Created Date</th>
                <th class="app-w-10p">Updated Date</th>
                <th class="app-w-5p d-none d-md-table-cell">Receive Status</th>
                <th class="app-w-5p d-none d-md-table-cell">Send Status</th>
<!--                <th></th>-->
              </tr>
              </thead>
              <tbody v-if="!loading && results && results.data">
              <tr v-for="result in results.data" :key="result.id">
                <td>{{ result.id }}</td>
                <td>{{ result.tableUuid }}</td>
                <td>{{ result.tableName }}</td>
                <td>{{ result.topic }}</td>
                <td>{{ result.kafkaOffset }}</td>
                <td>{{ result.partition }}</td>
                <td>{{ result.createdDate }}</td>
                <td>{{ result.updatedDate }}</td>
                <td>{{ result.receiveStatus }}</td>
                <td>{{ result.sendStatus }}</td>
<!--                <td>-->
<!--                  <div class="btn-group btn-group-sm">-->
<!--                    <router-link :to="'/sign/view/' + result.id" tag="button" class="btn btn-success">-->
<!--                      <i class="fa fa-pencil"></i>-->
<!--                    </router-link>-->
<!--                  </div>-->
<!--                </td>-->
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <div class="loader mt-5 mb-5" v-if="loading"></div>
              <span class="no-data mt-5 mb-5" v-if="!loading && results && results.data && results.data.length===0">Нет данных</span>
            </div>
            <div class="row" v-if="!loading && results">
              <div class="col-2 pt-3 pb-3 text-left">
                <div class="pl-3">
                  <b>Umumiy : <i><span>{{ results.total | currency('', 0, { thousandsSeparator: ' ' })}}</span></i></b>
                </div>
              </div>
              <div class="col-10 pt-3 pb-3">
                <b-pagination :total-rows="results ? results.total : 0" v-model="filter.page.page" :per-page="filter.page.size" @change="onPageChange"></b-pagination>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BPagination} from 'bootstrap-vue'
import initFilterModel from "@/shared/filter"

export default {
  name: "KafkaEventList",
  components: {
    'b-pagination': BPagination
  },
  data() {
    return {
      loading: false,
      filter: initFilterModel(),
      results: null,
    }
  },
  methods: {
    initList() {
      this.loading = true
      this.$http.post('kafka/list', this.filter)
          .then(res => {
            this.results = res.data
            this.loading = false
          }, err => {
            console.log(err)
            this.loading = false
          })
    },
    onPageChange(page) {
      this.filter.page.page = page
      this.initList()
    }
  },
  created() {
    this.initList()
  }
}
</script>

<style scoped>

</style>